<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g id="Icona_Data_unica" transform="translate(-716 -1014)">
      <rect id="Icon_fin_spacer" width="48" height="48" transform="translate(716 1014)" fill="none"/>
      <g id="Icona_cofidis_data_unica" transform="translate(716.05 1013.92)">
        <path id="Path_8116" data-name="Path 8116" d="M37.4,3.54V1.08H35.15V3.54H12.85V1.08H10.6V3.54H1.95V46.29H46.04V3.54ZM4.2,44.04V17.42H43.8V44.05H4.2ZM11.72,9a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,11.72,9ZM10.6,6.94a3.75,3.75,0,1,0,2.24,0V5.79H35.15V6.94a3.745,3.745,0,1,0,2.25,0V5.79h6.39v9.37H4.2V5.79h6.39V6.94ZM36.28,9a1.5,1.5,0,1,1-1.5,1.5A1.5,1.5,0,0,1,36.28,9Z" fill="#252525"/>
        <path id="Path_8117" data-name="Path 8117" d="M12.44,26.79l1.24,2.05,3.89-2.56V39.96H20.2V23.11H17.96Z" fill="#252525"/>
        <path id="Path_8118" data-name="Path 8118" d="M34.25,23.65a6.561,6.561,0,0,0-3.44-.81,5.534,5.534,0,0,0-4.7,2.06c-1.03,1.36-1.53,3.56-1.53,6.74s.51,5.35,1.56,6.63a5.74,5.74,0,0,0,4.69,1.93,5.665,5.665,0,0,0,4.68-1.96c1.04-1.29,1.55-3.41,1.55-6.47C37.06,27.43,36.14,24.78,34.25,23.65Zm.13,7.97c0,2.42-.3,4.09-.89,4.95a2.98,2.98,0,0,1-2.67,1.23,3,3,0,0,1-2.67-1.22c-.59-.85-.88-2.46-.88-4.78,0-3.27.53-5.29,1.58-6.01a3.332,3.332,0,0,1,1.98-.51,2.878,2.878,0,0,1,2.69,1.3C34.09,27.49,34.38,29.19,34.38,31.62Z" fill="#252525"/>
      </g>
    </g>
  </svg>
</template>

<style scoped></style>
